export const environment = {
  name: 'qa',
  production: false,

  environmentBaseUrl: '',

  appBaseHref: '/ux',
  apiBaseUrl: '/uxapi/v1',

  agGridLicenseKey: 'Using_this_AG_Grid_Enterprise_key_( AG-044377 )_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_changing_this_key_please_contact_( info@ag-grid.com )___( CENTRL Office Downtown Portland, Sitka Technology Group, LLC )_is_granted_a_( Multiple Applications )_Developer_License_for_( 1 ))_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_need_to_be_licensed_in_addition_to_the_ones_working_with_AG_Grid_Enterprise___This_key_has_been_granted_a_Deployment_License_Add-on_for_( 1 )_Production_Environment___This_key_works_with_AG_Grid_Enterprise_versions_released_before_( 31 August 2024 )____[v2]_MTcyNTA1ODgwMDAwMA==0d9cb21c611aa334010c5a29380a66c7',
  appInsightsKey: 'a5ce38d5-26e2-46ba-a489-279cb821555a',
  gojsLicenseKey: '73f947e5bb6231b700ca0d2b113f69ef1bb37b359e841ff55e5341f2ef5f691d2bcbed7b59db8f90d0ad4eaf1c2ec289cfd56e21c419043cb733878e40e787aee23276e61d0b42dba20026c29efb29f5af7b75fa96b474aad92a84f3b9aec6954ef8f58118cc0ee979791421557fac48a8f8c67bff',
  googleAnalyticsKey: '',
  recaptchaKey: '6Lc8dksUAAAAACr__RNtoSlEYrQ6meIUudBKuMOr',

  featureFlags: {
    newWidget: true,
    smartGuide: true,
    tocGuide: true,
  },
};
