/**
 * Miradi Share UX Services
 * Miradi Share UX Services end-point (for internal use only)
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { MiradiSubscription } from './miradiSubscription';
import { ProjectPublishStatusCount } from './projectPublishStatusCount';


/**
 * Miradi Share Program info
 */
import { ValidateUtils } from 'app/shared/utils';

export class ProgramInfo { 
    /**
     * Unique identifier
     */
    readonly identifier?: string;
    /**
     * Program name
     */
    readonly name?: string;
    /**
     * Program short name
     */
    readonly shortName?: string;
    /**
     * Organization identifier (that owns program)
     */
    readonly owningOrganizationIdentifier?: string;
    /**
     * Organization name (that owns program)
     */
    readonly owningOrganizationName?: string;
    /**
     * Miradi object resource identifier
     */
    contactResourceIdentifier?: string;
    /**
     * First name for Contact
     */
    readonly contactFirstName?: string;
    /**
     * Last name for Contact
     */
    readonly contactLastName?: string;
    /**
     * Email for Contact
     */
    readonly contactEmail?: string;
    /**
     * Flag indicating whether Program is private (or public)
     */
    isPrivate?: boolean;
    /**
     * Flag indicating whether Program auto enrolls Organization members
     */
    autoEnrollOrganizationMembers?: boolean;
    /**
     * Project count
     */
    readonly projectCount?: number;
    /**
     * Count of projects by publish status
     */
    readonly projectPublishStatusCounts?: Array<ProjectPublishStatusCount>;
    /**
     * Program member count
     */
    readonly memberCount?: number;
    /**
     * Organization member count
     */
    readonly organizationMemberCount?: number;
    miradiSubscription?: MiradiSubscription;

    constructor(item?: Partial<ProgramInfo>) {
        
        Object.assign(this, item);
    }

    static getValidationObject(): any {
        return {
            identifier: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.identifier : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            name: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.name : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            shortName: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.shortName : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            owningOrganizationIdentifier: {
                type: 'uuid',
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.owningOrganizationIdentifier : itemOrValue;

                    if (itemOrValue?.owningOrganizationIdentifier != null) {
                        if (itemOrValue.owningOrganizationIdentifier === '') {
                            itemOrValue.owningOrganizationIdentifier = undefined;
                        } else if (['int32', 'int64'].indexOf('uuid') >= 0) {
                            itemOrValue.owningOrganizationIdentifier = parseInt(itemOrValue.owningOrganizationIdentifier.toString(), 10);
                        } else if (['float'].indexOf('uuid') >= 0) {
                            itemOrValue.owningOrganizationIdentifier = parseFloat(itemOrValue.owningOrganizationIdentifier.toString());
                        }
                    }
                    result = result && ValidateUtils.isValidType(value, 'uuid');
                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            owningOrganizationName: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.owningOrganizationName : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            contactResourceIdentifier: {
                type: 'uuid',
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.contactResourceIdentifier : itemOrValue;

                    if (itemOrValue?.contactResourceIdentifier != null) {
                        if (itemOrValue.contactResourceIdentifier === '') {
                            itemOrValue.contactResourceIdentifier = undefined;
                        } else if (['int32', 'int64'].indexOf('uuid') >= 0) {
                            itemOrValue.contactResourceIdentifier = parseInt(itemOrValue.contactResourceIdentifier.toString(), 10);
                        } else if (['float'].indexOf('uuid') >= 0) {
                            itemOrValue.contactResourceIdentifier = parseFloat(itemOrValue.contactResourceIdentifier.toString());
                        }
                    }
                    result = result && ValidateUtils.isValidType(value, 'uuid');
                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            contactFirstName: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.contactFirstName : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            contactLastName: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.contactLastName : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            contactEmail: {
                type: 'email',
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.contactEmail : itemOrValue;

                    if (itemOrValue?.contactEmail != null) {
                        if (itemOrValue.contactEmail === '') {
                            itemOrValue.contactEmail = undefined;
                        } else if (['int32', 'int64'].indexOf('email') >= 0) {
                            itemOrValue.contactEmail = parseInt(itemOrValue.contactEmail.toString(), 10);
                        } else if (['float'].indexOf('email') >= 0) {
                            itemOrValue.contactEmail = parseFloat(itemOrValue.contactEmail.toString());
                        }
                    }
                    result = result && ValidateUtils.isValidType(value, 'email');
                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            isPrivate: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.isPrivate : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            autoEnrollOrganizationMembers: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.autoEnrollOrganizationMembers : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            projectCount: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.projectCount : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            projectPublishStatusCounts: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.projectPublishStatusCounts : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            memberCount: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.memberCount : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            organizationMemberCount: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.organizationMemberCount : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            miradiSubscription: {
                
                
                
                isValid: (itemOrValue: any) => {
                    let result = true;

                    let value = itemOrValue === Object(itemOrValue) ? itemOrValue.miradiSubscription : itemOrValue;

                    result = result && ValidateUtils.isValidLength(value, null, );

                    return result;
                }
            },
            isValid: ProgramInfo.isValid,
        };
    }

    static isValid(obj: ProgramInfo): boolean {
        if (!!obj) {
            const item = obj as any; // just to make things easier in this generated code...
            const validationObject = ProgramInfo.getValidationObject();
            let result = !item.$validationMessage;
            result = result && validationObject.identifier.isValid(item);
            result = result && validationObject.name.isValid(item);
            result = result && validationObject.shortName.isValid(item);
            result = result && validationObject.owningOrganizationIdentifier.isValid(item);
            result = result && validationObject.owningOrganizationName.isValid(item);
            result = result && validationObject.contactResourceIdentifier.isValid(item);
            result = result && validationObject.contactFirstName.isValid(item);
            result = result && validationObject.contactLastName.isValid(item);
            result = result && validationObject.contactEmail.isValid(item);
            result = result && validationObject.isPrivate.isValid(item);
            result = result && validationObject.autoEnrollOrganizationMembers.isValid(item);
            result = result && validationObject.projectCount.isValid(item);
            result = result && validationObject.projectPublishStatusCounts.isValid(item);
            result = result && validationObject.memberCount.isValid(item);
            result = result && validationObject.organizationMemberCount.isValid(item);
            result = result && validationObject.miradiSubscription.isValid(item);
            return result;
        } else {
            return false
        }
    }
}

