import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseDialog } from 'app/shared/dialogs/base/base.dialog';
import { CustomReport, CustomReportNew, GlobalBusyService, NotificationService, Portfolio, ProgramsService, ProjectsService } from 'app/shared/services';
import { AuthService } from 'app/shared/services/auth/auth.service';



@Component({
  selector: 'miradi-edit-custom-report-dialog',
  templateUrl: './edit-custom-report.dialog.html',
  styleUrls: ['./edit-custom-report.dialog.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditCustomReportDialog extends BaseDialog {

  deleteConfirmMode: boolean;
  editableItem: Portfolio;
  programIdentifier: string;
  projectIdentifier: string;

  validationObject: any;

  constructor(
    authService: AuthService,
    private globalBusyService: GlobalBusyService,
    cdr: ChangeDetectorRef,
    el: ElementRef,
    private translateService: TranslateService,
    private notificationService: NotificationService,
    private programsService: ProgramsService,
    private projectsService: ProjectsService,
  ) {
    super(authService, cdr, el);

    this.validationObject = CustomReportNew.getValidationObject();
  }

  init(data: any) {
    this.deleteConfirmMode = false;
    this.validationMessages = [];

    if (data) {
      this.programIdentifier = data.programIdentifier;
      this.projectIdentifier = data.projectIdentifier;
      this.editableItem = data.editableItem;
    } else {
      this.editableItem = undefined;
    }
  }

  save() {
    this.validationMessages = [];
    this.cdr.markForCheck();

    if (!this.validationObject.name.isValid(this.editableItem)) {
      this.pushValidationMessage({
        severity: 'error',
        summary: this.translateService.instant('Name:'),
        detail: this.translateService.instant('Required field.'),
      });
      this.cdr.markForCheck();
    } else if (this.validationObject.isValid(this.editableItem)) {
      this.globalBusyService.setBusy(true);

      let request;
      if (this.programIdentifier) {
        request = this.editableItem.resourceIdentifier ?
        this.programsService.updateProgramCustomReport(
          this.programIdentifier,
          this.editableItem.resourceIdentifier,
          this.editableItem
        ) :
        this.programsService.createProgramCustomReport(
          this.programIdentifier,
          this.editableItem
        );
      } else {
        request = this.editableItem.resourceIdentifier ?
        this.projectsService.updateProjectCustomReport(
          this.projectIdentifier,
          this.editableItem.resourceIdentifier,
          this.editableItem
        ) :
        this.projectsService.createProjectCustomReport(
          this.projectIdentifier,
          this.editableItem
        );
      }

      request.subscribe((newItem: CustomReport) => {
        this.globalBusyService.setBusy(false);
        this.notificationService.success(
          this.translateService.instant('Success'),
          this.translateService.instant('Changes saved successfully.')
        );

        this.close(newItem || this.editableItem);
      }, (error: any) => {
        this.globalBusyService.setBusy(false);
        this.cdr.markForCheck();
      });
    }
  }

  delete() {
    this.globalBusyService.setBusy(true);

    let request;
    if (this.programIdentifier) {
      request = this.programsService.deleteProgramCustomReport(
        this.programIdentifier,
        this.editableItem.resourceIdentifier,
      );
    } else {
      request = this.projectsService.deleteProjectCustomReport(
        this.projectIdentifier,
        this.editableItem.resourceIdentifier,
      )
    }

    request.subscribe((empty: void) => {
      this.globalBusyService.setBusy(false);
      this.notificationService.success(
              this.translateService.instant('Success'),
              this.translateService.instant('Item deleted successfully.')
            );

      (this.editableItem as any).$deleted = true;

      this.close(this.editableItem);
    }, (error: any) => {
      this.globalBusyService.setBusy(false);

      this.cdr.markForCheck();
    });
  }

}
